<template>
  <div >

    <!-- Linked list group -->
    <div class="card">

      <div class="card-body">
        <h6 class="mb-0">Dr.William Brenson</h6>
        <span class="text-muted">MBBS Frcs MD</span>
        <p class="card-text">Karur</p>
      </div>

      <ul class="list-group border-x-0 rounded-0 border-top">

        <div href="#" class="list-group-item list-group-item-action">
          <select class="form-control">
            <option v-for="item in $store.state.user.products"> {{item.name}}</option>
          </select>
        </div>

        <div href="#" class="list-group-item list-group-item-action">
          <select class="form-control">
            <option v-for="item in $store.state.user.products"> {{item.name}}</option>
          </select>
        </div>

        <div href="#" class="list-group-item list-group-item-action">
          <select class="form-control">
            <option v-for="item in $store.state.user.products"> {{item.name}}</option>
          </select>
        </div>

      </ul>

      <div class="card-footer d-flex justify-content-between border-top">
        <span class="text-muted">Visited 2 days ago</span>
        <button class="btn btn-outline-success">Update</button>
      </div>
    </div>
    <!-- /linked list group -->

  </div>
</template>

<script>
export default {
  name: 'ReminderCard',
  props:{

  },
  data(){
    return {
    }
  },
  created () {
  },
  mounted () {
  },
  methods:{

  }
}
</script>

<style scoped>

</style>
