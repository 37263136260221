<template>
  <FocusTrap>
    <div class="content">

      <!-- Horizontal cards -->
      <div class="mb-3 pt-2">
        <h6 class="mb-0 font-weight-semibold">
          Reminder Cards
        </h6>
        <span class="text-muted d-block">{{date_title}}</span>
      </div>

      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <div class="card card-body">
            <div class="media">

              <div class="mr-3">
                <a href="#">
                  <img src="static/global_assets/images/demo/users/doctor_logo.jpg" class="rounded-circle" width="42" height="42" alt="doctor-logo">
                </a>
              </div>

              <div class="media-body">
                <h6 class="mb-0">Dr.Name</h6>
                <span class="text-muted">MBBS Frcs MD</span>
<!--                <div>-->
<!--                  <span class="font-weight-semibold">Karur</span>-->
<!--                </div>-->
              </div>

            </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-6">
          <div class="card card-body">
            <div class="media">
              <div class="mr-3">
                <a href="#">
                  <img src="static/global_assets/images/demo/users/face14.jpg" class="rounded-circle" width="42" height="42" alt="">
                </a>
              </div>

              <div class="media-body">
                <h6 class="mb-0">Benjamin Loretti</h6>
                <span class="text-muted">Network engineer</span>
              </div>

              <div class="ml-3 align-self-center">
                <div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown"><i class="icon-menu7"></i></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="#" class="dropdown-item"><i class="icon-comment-discussion"></i> Start chat</a>
                      <a href="#" class="dropdown-item"><i class="icon-phone2"></i> Make a call</a>
                      <a href="#" class="dropdown-item"><i class="icon-mail5"></i> Send mail</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-6">
          <div class="card card-body">
            <div class="media">
              <div class="mr-3">
                <a href="#">
                  <img src="static/global_assets/images/demo/users/face13.jpg" class="rounded-circle" width="42" height="42" alt="">
                </a>
              </div>

              <div class="media-body">
                <h6 class="mb-0">Vanessa Aurelius</h6>
                <span class="text-muted">Front end guru</span>
              </div>

              <div class="ml-3 align-self-center">
                <div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown"><i class="icon-menu7"></i></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="#" class="dropdown-item"><i class="icon-comment-discussion"></i> Start chat</a>
                      <a href="#" class="dropdown-item"><i class="icon-phone2"></i> Make a call</a>
                      <a href="#" class="dropdown-item"><i class="icon-mail5"></i> Send mail</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-6">
          <div class="card card-body">
            <div class="media">
              <div class="mr-3">
                <a href="#">
                  <img src="static/global_assets/images/demo/users/face8.jpg" class="rounded-circle" width="42" height="42" alt="">
                </a>
              </div>

              <div class="media-body">
                <h6 class="mb-0">William Brenson</h6>
                <span class="text-muted">Chief officer</span>
              </div>

              <div class="ml-3 align-self-center">
                <div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown"><i class="icon-menu7"></i></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="#" class="dropdown-item"><i class="icon-comment-discussion"></i> Start chat</a>
                      <a href="#" class="dropdown-item"><i class="icon-phone2"></i> Make a call</a>
                      <a href="#" class="dropdown-item"><i class="icon-mail5"></i> Send mail</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /horizontal cards -->

      <div class="row">
        <div class="col-md-4">
          <ReminderCard/>
        </div>
      </div>
    </div>
    <!-- /content area -->
  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import ReminderCard from '../../../components/ReminderCard'
export default {
  name: 'ReminderCall',
  components: { ReminderCard },
  store,
  data(){
    return {
      working_date : moment(),
      date_title : '',
      doctor_dobs : [],
      doctor_wobs : [],
    }
  },
  created () {
    this.$data.working_date = this.$store.state.user.working_date;
  },
  mounted () {
    const self = this;
    self.$data.date_title = moment(self.$data.working_date).format('ll');
    try {
      self.loadBirthDayData();
      self.loadWeddingDayData();
    }catch (e) {
      alert(e);
    }
  },
  methods:{
    loadBirthDayData(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      try {

        $('#greetings_card').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.doctor_dobs = [];

        //alert(`${process.env.VUE_APP_ROOT_API}api/dayplans/doctor/birthdays/${self.$store.state.user.territory_id}`);
        fetch(`${process.env.VUE_APP_ROOT_API}api/dayplans/doctor/birthdays/${self.$store.state.user.territory_id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          $('#greetings_card').unblock();

          if (resp.ok) {

            if (!_.isNull(resp.data)) {
              self.$data.doctor_dobs = resp.data;


              console.log(JSON.parse(resp.data));

              // $('#area_table').bootstrapTable('load', self.$store.state.user.areas);
              // $('#area_table').bootstrapTable('checkBy', {field: 'id', values: self.$data.today_areas});

            }

          } else {
            swal({ title: "Oops!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        }).finally(function () {
          $('#greetings_card').unblock();
        });
      }catch (e) {
        alert(e);
      }
    },
    loadWeddingDayData(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      try {

        $('#greetings_card').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.doctor_wobs = [];

        fetch(`${process.env.VUE_APP_ROOT_API}api/dayplans/doctor/weddingdays/${self.$store.state.user.territory_id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          $('#greetings_card').unblock();

          if (resp.ok) {

            if (!_.isNull(resp.data)) {
              self.$data.doctor_wobs = resp.data;

              console.log(JSON.parse(resp.data));

            }

          } else {
            swal({ title: "Oops!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        }).finally(function () {
          $('#greetings_card').unblock();
        });
      }catch (e) {
        alert(e);
      }
    },
  }
}
</script>

<style scoped>

</style>
